import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import './Slider.css';

// import swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { SliderProducts } from "../../data/products"
import { Navigation, Pagination } from 'swiper';

const Slider = () => {
    return (
        <div className="s-container">
            <Swiper 
            breakpoints={{
               640: {
                slidesPerView: 3,
               },
               0: {
                slidesPerView: 1,
               } 
            }}
            className='mySwiper' 
            navigation={true}
            loopFillGroupWithBlank={true}
            modules={[Pagination, Navigation]} 
            slidesPerView={3} spaceBetween={40} slidesPerGroup={1} loop={true} >
                {
                    SliderProducts.map((slide, i) => (
                        <SwiperSlide>
                            
                            <div className="left-s">
                                <div className="name">
                                    <span>{slide.name}</span>
                                    <span>{slide.detail}</span>
                                </div>

                                <span>{slide.price}$</span>
                                <div>Shop now</div>
                            </div>

                            <img src={slide.img} alt="product" className='img-p'/>  
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}

export default Slider