import React from 'react';
import css from './Footer.module.css';
import Logo from '../../assets/logo.png';
import { InboxIcon, PhoneIcon, LocationMarkerIcon, LoginIcon, UsersIcon, LinkIcon } from '@heroicons/react/outline';

const Footer = () => {
    return (
        <div className={css.cFooterWrapper}>
            <hr />


            <div className={css.cFooter}>
                <div className={css.logo}>
                    <img src={Logo} alt="" />
                    <span>Abhishek</span>
                </div>

                {/* contact us */}
                <div className={css.block}>
                    <div className={css.detail}>
                        <span>Contact Us</span>
                        <span className={css.pngLine}>
                            <LocationMarkerIcon className={css.icon} />
                            <span>111 north avenue Orlando, FL 32801</span>
                        </span>

                        <span className={css.pngLine}>
                            {" "}
                            <PhoneIcon className={css.icon} />
                            <a href="tel:112-444-3332">112-444-3332</a>
                        </span>

                        <span className={css.pngLine}>
                            <InboxIcon className={css.icon} />
                            <a href="mailto:support@abhi.com">support@abhishek.com</a>
                        </span>
                    </div>
                </div>

                {/* account  */}
                <div className={css.block}>
                    <div className={css.detail}>
                        <span>Account</span>
                        <span className={css.pngLine}>
                            <LoginIcon className={css.icon} />
                            Sign In
                        </span>
                    </div>
                </div>

                {/* Company */}
                <div className={css.block}>
                    <div className={css.detail}>
                        <span>Company</span>
                        <span className={css.pngLine}>
                            <UsersIcon className={css.icon} />
                            <a href='/about'>
                                <p>About Us</p>
                            </a>
                        </span>
                    </div>
                </div>

                {/* Resources */}
                <div className={css.block}>
                    <div className={css.detail}>
                        <span>Resources</span>
                        <span className={css.pngLine}>
                            <LinkIcon className={css.icon} />
                            <span>Safety Privacy & Terms</span>
                        </span>
                    </div>
                </div>
            </div>

            {/* copyright */}
            <div className={css.copyRight}>
                <span>Copyright @2022 by Amazon, Inc.</span>
                <span>All rights reserved.</span>
            </div>

        </div>
    )
}

export default Footer